<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :get-data-vuex="getUsers"
        :placeholder="placeholder"
        :multiple="multiple"
        :value="value"
        class="white"
        field="login"
        @[event]="$emit(event, $event)">
        <template #text="option">
            <UserOutput
                :user="option.option"
                :int-id="$saas.visibleLoginWebmaster || intId"
                :login="$saas.visibleLoginWebmaster">
            </UserOutput>
        </template>
        <template #selected-option="option">
            <UserOutput
                :user="option.option"
                :int-id="$saas.visibleLoginWebmaster || intId"
                :login="$saas.visibleLoginWebmaster">
            </UserOutput>
        </template>
    </Component>
</template>
<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "WebmastersSelect",
    mixins: [UsersSelect],

    components: { UserOutput },

    props: {
      routeParam: {
        type: String,
        default: "webmasterId"
      },

      namespaceModule: {
        type: String,
        default: "webmastersFilters"
      }
    },

    computed: {
      ...mapGetters(["role"])
    },

    methods: {
      async getUsers (label = "", isLoading = false) {
        await this.getUserFilters(this.namespaceModule, this.list, this.$route.query[this.routeParam] ?? this.value,
                                  this.routeParam, label, isLoading, this.filterOptions);
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>